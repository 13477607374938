export function parseCoordinatesHeatLayer(data: any) {
  let coordinates: any[] = [];
  // let points: any[] = [];
  // let timesToAdd: number[] = [];

  // if (
  //   data.series.find((series: any) => series.refId === 'L').meta!.custom!.data!
  //     .length > 0
  // ) {
  //   data.series
  //     .find((series: any) => series.refId === 'L')
  //     .meta!.custom!.data.map((item: any) => {
  //       points.push([item['latitude'], item['longitude']]);  // Simplemente é unha lista cada punto
  //       timesToAdd.push(item['_value']);
  //     });

  //   for (let i = 0; i < points.length; i++) {
  //     coordinates = coordinates.concat(Array(timesToAdd[i]).fill(points[i]));
  //   }
  // }

  if (data.series.find((series: any) => series.refId === 'L').fields.length > 0) {
    let latitude: number[] = data.series.find((series: any) => series.refId === 'L').fields[1].values;
    let longitude: number[] = data.series.find((series: any) => series.refId === 'L').fields[2].values;

    for (let i = 0; i < latitude.length; i++) {
      coordinates.push([longitude[i], latitude[i]]);
    }
  }

  return coordinates;
}
